import FieldErrorMessage from 'components/field-error-message'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

const useForgotPasswordForm = () => {
  const { register, errors, handleSubmit } = useForm()
  const [formState, setFormState] = useState({
    submitting: false,
    error: false,
    submitted: false,
  })

  const submitPasswordRequest = async ({ email }) => {
    try {
      setFormState({
        submitting: true,
        error: null,
        submitted: false,
      })

      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)

      setFormState({
        submitting: false,
        error: null,
        submitted: true,
      })
    } catch (e) {
      setFormState({
        submitting: false,
        error: e.errorCode || e.code,
      })
    }
  }

  return {
    register,
    onSubmit: handleSubmit(submitPasswordRequest),
    submitting: formState.submitting,
    error: formState.error,
    submitted: formState.submitted,
    errors,
  }
}

export default function ForgotPasswordForm() {
  const {
    register,
    onSubmit,
    submitting,
    submitted,
    error,
    errors,
  } = useForgotPasswordForm()
  const handleSubmit = (e) => {
    e.stopPropagation()
    onSubmit(e)
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="text-red text-center mb-2 font-semibold">
        <FormattedMessage id="login.forgotPassword.form.title" />
      </div>

      <div className="text-center mb-10">
        <FormattedMessage id="login.forgotPassword.form.subtitle" />
      </div>

      <label className="block">
        <div className="font-bold">
          <FormattedMessage id="login.forgotPassword.form.email" />
        </div>

        <div>
          <input
            name="email"
            type="email"
            ref={register({ required: 'required' })}
            className="w-full py-2 border-b bg-transparent border-grey-cool outline-none"
          />
        </div>

        {errors.email && (
          <FieldErrorMessage>
            <FormattedMessage
              id={`login.forgotPassword.form.errors.email.${errors.email.type}`}
            />
          </FieldErrorMessage>
        )}
      </label>

      {error && (
        <div className="text-red mt-8">
          <FormattedMessage
            id={`login.forgotPassword.form.errors.errorCode.${error.errorCode}`}
            defaultMessage={error.message}
          />
        </div>
      )}

      {submitted && (
        <div className="text-red mt-8">
          <FormattedMessage id="login.forgotPassword.form.submittedMessage" />
        </div>
      )}

      {!submitted && (
        <div className="mt-12 text-center">
          <button
            disabled={submitting}
            className="bg-red-faded text-pink-light py-4 px-20 rounded-lg uppercase
          disabled:bg-grey-light disabled:text-white font-lemonmilk font-light
          tracking-wider lg:w-auto"
          >
            <FormattedMessage id="login.forgotPassword.form.confirmButton" />
          </button>
        </div>
      )}
    </form>
  )
}
