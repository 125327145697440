import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth'

const usePasswordForm = () => {
  const { register, errors, handleSubmit } = useForm()
  const [formState, setFormState] = useState({
    submitting: false,
    error: false,
    submitted: false,
  })

  const submitPasswordRequest = async ({ currentPassword, newPassword }) => {
    try {
      setFormState({
        submitting: true,
        error: null,
        submitted: false,
      })

      const auth = getAuth()
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      )
      await reauthenticateWithCredential(auth.currentUser, credential)
      await updatePassword(auth.currentUser, newPassword)

      setFormState({
        submitting: false,
        error: null,
        submitted: true,
      })
    } catch (e) {
      setFormState({
        submitting: false,
        error: e.errorCode || e.code,
      })
    }
  }

  return {
    register,
    onSubmit: handleSubmit(submitPasswordRequest),
    submitting: formState.submitting,
    error: formState.error,
    submitted: formState.submitted,
    errors,
  }
}

export default function PasswordForm() {
  const { register, onSubmit, submitting, submitted, error } = usePasswordForm()
  const handleSubmit = (e) => {
    e.stopPropagation()
    onSubmit(e)
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="text-red text-center mb-10 font-semibold">
        <FormattedMessage id="account.changePasswordForm.form.title" />
      </div>

      <label className="block mb-8">
        <div className="font-bold">
          <FormattedMessage id="account.changePasswordForm.form.currentPassword" />
        </div>

        <div>
          <input
            name="currentPassword"
            type="password"
            autoComplete="current-password"
            ref={register({ required: 'required' })}
            className="w-full py-2 border-b bg-transparent border-grey-cool outline-none"
          />
        </div>
      </label>

      <label className="block mb-8">
        <div className="font-bold">
          <FormattedMessage id="account.changePasswordForm.form.newPassword" />
        </div>

        <div>
          <input
            name="newPassword"
            type="password"
            autoComplete="new-password"
            ref={register({ required: 'required' })}
            className="w-full py-2 border-b bg-transparent border-grey-cool outline-none"
          />
        </div>
      </label>

      {error && (
        <div className="text-red mt-8">
          <FormattedMessage
            id={`account.changePasswordForm.form.errors.errorCode.${error.errorCode}`}
            defaultMessage={error.message}
          />
        </div>
      )}

      {submitted && (
        <div className="text-red mt-8">
          <FormattedMessage id="account.changePasswordForm.form.submittedMessage" />
        </div>
      )}

      {!submitted && (
        <div className="mt-12 text-center">
          <button
            disabled={submitting}
            className="bg-red-faded text-pink-light py-4 px-20 rounded-lg uppercase
          disabled:bg-grey-light disabled:text-white font-lemonmilk font-light
          tracking-wider lg:w-auto"
          >
            <FormattedMessage id="account.changePasswordForm.form.confirmButton" />
          </button>
        </div>
      )}
    </form>
  )
}
