import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import DockedButton from 'components/docked-button'
import Button from 'components/button'
import { useForm } from 'react-hook-form'
import CustomerApi from 'services/apis/customer-api'
import { useQuery } from 'react-query'

const useOptinForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { errors, setError, ...formMethods } = useForm()
  const [submitting, setSubmitting] = useState(false)

  const { isLoading, data } = useQuery(
    ['account'],
    () => CustomerApi.fetch('/v1/user'),
    {
      refetchOnWindowFocus: false,
    }
  )

  const optIn = async () => {
    try {
      const { from } = location.state || { from: { pathname: '/' } }

      setSubmitting(true)

      await CustomerApi.fetch('/v1/user', {
        method: 'PUT',
        body: JSON.stringify({
          user: {
            accepts_marketing_emails: true,
          },
        }),
      })

      navigate(from)
    } catch (error) {
      console.error(error)
      setSubmitting(false)
    }
  }

  const handleReject = useCallback(() => {
    const { from } = location.state || { from: { pathname: '/' } }
    navigate(from)
  }, [navigate, location.state])

  return {
    onSubmit: formMethods.handleSubmit(optIn),
    onReject: handleReject,
    submitting,
    user: data?.user,
    isLoading,
  }
}

const Form = ({ user, onSubmit, submitting, onReject }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="text-right">
        <button onClick={onReject} className="underline text-xs">
          <FormattedMessage
            id="signup.optin.refuse"
            defaultMessage="Passer cette étape"
          />
        </button>
      </div>

      <div className="mt-12 lg:mt-8">
        <FormattedMessage
          id="signup.optin.explanation"
          values={{
            p: (chunks) => <p className="mb-8">{chunks}</p>,
            name: user.contact?.first_name,
          }}
        />
      </div>

      <div className="mt-10 hidden lg:block text-center">
        <Button disabled={submitting}>
          <FormattedMessage
            id="signup.optin.signupButton"
            defaultMessage="Avec plaisir, merci"
          />
        </Button>
      </div>

      <DockedButton disabled={submitting}>
        <FormattedMessage
          id="signup.optin.signupButton"
          defaultMessage="Avec plaisir, merci"
        />
      </DockedButton>
    </form>
  )
}

export default function OptinForm() {
  const { isLoading, ...optinFormMethods } = useOptinForm()

  if (isLoading) {
    return null
  }

  return <Form {...optinFormMethods} />
}
