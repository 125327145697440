import { useReducer } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, queryCache } from 'react-query'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import CustomerApi from 'services/apis/customer-api'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TERMS_CHECKED':
      return {
        ...state,
        termsChecked: action.checked,
      }

    case 'SET_FORM_ERROR': {
      return {
        ...state,
        formError: action.error,
      }
    }

    case 'SUBMIT':
      return {
        ...state,
        formError: null,
        submitting: true,
        submitError: null,
      }

    case 'SUBMIT_FAILED':
      return {
        ...state,
        submitting: false,
        submitError: action.error,
      }

    case 'OPEN_MODAL':
      return {
        ...state,
        modalIsOpen: true,
      }

    case 'CLOSE_MODAL':
      return {
        ...state,
        modalIsOpen: false,
      }

    case 'DISABLE_FORM':
      return {
        ...state,
        formError: action.error,
        disabled: true,
      }

    case 'CREDIT_CARD_EXPIRED':
      return {
        ...state,
        creditCardExpired: true,
      }

    default:
      return state
  }
}

const useSummary = () => {
  const { reservationId } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const formMethods = useForm()

  const { error, data } = useQuery(
    ['reservations', reservationId],
    () => CustomerApi.fetch(`/v1/reservations/${reservationId}`),
    {
      retry: false,
      onSuccess: ({ reservation }) => {
        if (reservation.state === 'aborted') {
          updateUiState({
            type: 'DISABLE_FORM',
            error: intl.formatMessage({
              id: 'reservations.form.errors.aborted',
            }),
          })
        }
      },
    }
  )

  const reservation = data?.reservation

  const {
    error: creditCardListError,
    data: creditCardsData,
    ...creditCardsQueryStatus
  } = useQuery('credit_cards', () => CustomerApi.fetch('/v1/credit_cards'), {
    onSuccess: ({ credit_cards: creditCards }) => {
      if (creditCards.length > 0) {
        const creditCard = creditCards[0]
        const mTo = moment(reservation.to)
        const mExpCard = moment([
          creditCard.exp_year,
          creditCard.exp_month - 1,
          1,
        ])

        if (mTo.isBefore(mExpCard)) {
          formMethods.setValue('creditCard', creditCard.id)
          return
        }

        updateUiState({ type: 'CREDIT_CARD_EXPIRED' })
      }
    },
    enabled: !!reservation,
  })

  const creditCards = creditCardsData?.credit_cards

  const [validateReservation] = useMutation(
    () =>
      CustomerApi.fetch(`/v1/reservations/${reservationId}/validate`, {
        method: 'POST',
      }),
    {
      onSuccess: (data) =>
        queryCache.setQueryData(['reservations', reservationId], data),
      throwOnError: true,
    }
  )

  const [uiState, updateUiState] = useReducer(reducer, {
    formError: null,
    submitting: false,
    submitError: null,
    modalIsOpen: false,
    creditCardExpired: false,
  })

  const submit = async () => {
    updateUiState({
      type: 'SUBMIT',
    })

    try {
      await validateReservation(null, {
        onSuccess: () => navigate(`/reservations/${reservationId}/success`),

        onError: (error) =>
          updateUiState({
            type: 'SUBMIT_FAILED',
            error: error.message,
          }),
      })
    } catch (e) {
      updateUiState({
        type: 'SUBMIT_FAILED',
        error: e.message,
      })
    }
  }

  const handleOpenModal = () => updateUiState({ type: 'OPEN_MODAL' })
  const handleCloseModal = () => updateUiState({ type: 'CLOSE_MODAL' })

  const resultedError =
    error?.message ||
    creditCardListError?.error ||
    uiState.formError ||
    uiState.submitError

  return {
    reservation,
    creditCards,
    creditCard: creditCards && creditCards[0],
    isLoadingCreditCards:
      creditCardsQueryStatus.isIdle || creditCardsQueryStatus.isLoading,
    error: resultedError,
    onSubmit: formMethods.handleSubmit(submit),
    submitting: uiState.submitting,
    updateUiState,
    disabled: uiState.disabled || uiState.submitting,
    modalIsOpen: uiState.modalIsOpen,
    onOpenModalClick: handleOpenModal,
    onCloseModalClick: handleCloseModal,
    formMethods,
    creditCardExpired: uiState.creditCardExpired,
  }
}

export default useSummary
