import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import HeaderContent from 'domains/layout/header-content'
import PageTitle from 'components/page-title'
import { useQuery } from 'react-query'
import CustomerApi from 'services/apis/customer-api'

export default function ReservationSuccessPage() {
  const { reservationId } = useParams()

  const { data, isLoading } = useQuery(['reservations', reservationId], () =>
    CustomerApi.fetch(`/v1/reservations/${reservationId}`)
  )

  if (isLoading) {
    return null
  }

  const reservation = data.reservation
  const messagePrefix =
    reservation.state === 'broadcasted' && reservation.contractor?.id
      ? '.withContractor'
      : ''
  const contractorName = data.reservation.contractor?.display_name

  return (
    <>
      <HeaderContent>
        <PageTitle>
          <FormattedMessage id="reservations.success.pageTitle" />
        </PageTitle>
      </HeaderContent>

      <div>
        <div className="py-8 px-3 lg:bg-white lg:mt-20 lg:py-20 lg:rounded-lg lg:w-1/2 mx-auto">
          <div className="text-center">
            <div className="font-semibold">
              <FormattedMessage
                id={`reservations.success.message${messagePrefix}.1`}
                values={{ name: contractorName }}
              />
            </div>

            <div className="mt-8">
              <FormattedMessage
                id={`reservations.success.message${messagePrefix}.2`}
                values={{ name: contractorName }}
              />
            </div>

            <div className="mt-8">
              <FormattedMessage
                id={`reservations.success.message${messagePrefix}.3`}
                values={{ name: contractorName }}
              />
            </div>

            <div className="font-semibold mt-8">
              <FormattedMessage id={`reservations.success.message.4`} />
            </div>
          </div>
        </div>

        <div className="mt-20 text-center">
          <Link
            to="/"
            className={`fixed inset-x-0 bottom-0 bg-red-faded
            text-pink-light py-4 w-full uppercase disabled:bg-grey-light
            disabled:text-white font-lemonmilk font-light tracking-wider block
            text-center lg:hidden
            `}
          >
            <FormattedMessage id="reservations.success.home" />
          </Link>

          <Link
            to="/"
            className={`bg-red-faded
            text-pink-light py-4 px-8 w-full uppercase disabled:bg-grey-light
            disabled:text-white font-lemonmilk font-light tracking-wider
            text-center hidden lg:inline rounded-lg
            `}
          >
            <FormattedMessage id="reservations.success.home" />
          </Link>
        </div>
      </div>
    </>
  )
}
