import React, { useCallback } from 'react'
import cx from 'classnames'
import { FormattedDate } from 'react-intl'

export default function TimeslotPicker({
  availabilities,
  selectedDate,
  onSlotClick,
}) {
  const handleClick = useCallback(
    (e, from, to) => {
      e.preventDefault()
      onSlotClick(from, to)
    },
    [onSlotClick]
  )

  return (
    <div className="flex flex-wrap">
      {availabilities.map((availability) => (
        <div className="w-1/4" key={availability.from} style={{ height: 40 }}>
          <button
            className={cx(' w-full h-full block focus:outline-none', {
              'bg-pink-light rounded-lg font-semibold':
                availability.from === selectedDate,
            })}
            onClick={(e) => handleClick(e, availability.from, availability.to)}
          >
            <FormattedDate
              value={availability.from}
              hour="numeric"
              minute="numeric"
            />
          </button>
        </div>
      ))}
    </div>
  )
}
