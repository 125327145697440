import { useReducer } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import qs from 'query-string'
import CustomerApi from 'services/apis/customer-api'
import moment from 'moment'
import useContractorName from 'hooks/use-contractor-name'
import useLocalStorage from 'hooks/use-local-storage'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SELECTED_DATE':
      return {
        ...state,
        selectedDate: action.selectedDate,
      }

    case 'SELECTED_START_DATE':
      return {
        ...state,
        startDate: action.startDate,
        selectedDate: action.startDate,
      }

    case 'SELECTED_SLOT':
      return {
        ...state,
        selectedTimeSlot: action.timeslot,
        selectedEndTimeSlot: action.endTimeslot,
      }

    case 'INIT_RANGE':
      return {
        ...state,
        startDate: action.startDate,
        selectedDate: action.startDate,
      }

    case 'SUBMIT':
      return {
        ...state,
        submitting: true,
      }

    case 'SUBMIT_FAILED':
      return {
        ...state,
        submitting: false,
        error: action.error,
      }

    default:
      return state
  }
}

const useTreatmentSchedule = () => {
  const { postalCode, treatmentId } = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { contractor, contractorUserName } = useContractorName()
  const navigate = useNavigate()
  const [geocode] = useLocalStorage('geocode')

  const contractorId = contractor?.id || searchParams.get('contractor_id')

  const { isLoading, isError, error, data, isSuccess } = useQuery(
    ['availabilities', { contractorId, treatmentId, postalCode }],
    () =>
      CustomerApi.fetch(
        qs.stringifyUrl(
          {
            url: `/v1/treatments/${treatmentId}/availabilities`,
            query: {
              postcode: postalCode,
              contractor_id: contractorId,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        )
      ),
    {
      enabled: !!contractor || !contractorUserName,
      onSuccess: ({ availabilities }) => {
        if (!uiState.startDate) {
          const startDate = moment(availabilities[0].from).format('yyyy-MM-DD')
          updateUi({ type: 'INIT_RANGE', startDate })
        }
      },
      retry: false,
    }
  )

  const contractorsQuery = useQuery(
    ['contractors', postalCode, treatmentId],
    () =>
      CustomerApi.fetch(
        qs.stringifyUrl({
          url: `/v1/treatments/${treatmentId}/contractors?postcode`,
          query: {
            postcode: postalCode,
          },
        })
      ),
    {
      enabled: !contractor,
    }
  )

  const [createReservation] = useMutation(
    ({ from, to, treatmentId, contractorId }) =>
      CustomerApi.fetch('/v1/reservations', {
        method: 'POST',
        body: JSON.stringify({
          reservation: {
            from,
            to,
            treatment_id: treatmentId,
            contractor_id: contractorId || undefined,
            contact: {
              address: geocode.street,
              postcode: geocode.postalCode,
              city: geocode.city,
            },
          },
        }),
      }),
    {
      onSuccess: ({ reservation }) =>
        navigate(`/reservations/${reservation.id}/contact`),
      onError: ({ error }) =>
        updateUi({ type: 'SUBMIT_FAILED', error: error.message }),
    }
  )

  const availabilitiesByDate = data
    ? data.availabilities.reduce((acc, a10y) => {
        const date = moment(a10y.from).format('yyyy-MM-DD')

        return {
          ...acc,
          [date]: (acc[date] || []).concat([a10y]),
        }
      }, {})
    : {}

  const availabilitiesDates = Object.keys(availabilitiesByDate)
  const minStartDate = availabilitiesDates[0]
  const maxStartDate = availabilitiesDates[availabilitiesDates.length - 1]

  const [uiState, updateUi] = useReducer(reducer, {
    selectedDate: minStartDate,
    startDate: minStartDate,
    selectedTimeSlot: null,
    submitting: false,
  })

  const handleDayClick = (selectedDate) =>
    updateUi({ type: 'SELECTED_DATE', selectedDate })

  const handleSlotClick = (timeslot, endTimeslot) =>
    updateUi({ type: 'SELECTED_SLOT', timeslot, endTimeslot })

  const handleStartDateChange = (startDate) => {
    updateUi({
      type: 'SELECTED_START_DATE',
      startDate,
    })
  }

  const handleSubmit = async () => {
    await createReservation({
      from: uiState.selectedTimeSlot,
      to: uiState.selectedEndTimeSlot,
      treatmentId,
      contractorId,
    })
  }

  const handleContractorChange = (selectedContractorId) => {
    if (selectedContractorId) {
      navigate(
        {
          pathname: location.pathname,
          search: qs.stringify({
            ...qs.parse(location),
            contractor_id: selectedContractorId,
          }),
        },
        { replace: true }
      )

      return
    }

    navigate({
      pathname: location.pathname,
      search: qs.stringify(
        {
          ...qs.parse(location),
          contractor_id: undefined,
        },
        { replace: true }
      ),
    })
  }

  return {
    isLoading,
    isError,
    error,
    availabilities: availabilitiesByDate,
    minStartDate,
    maxStartDate,
    startDate: uiState.startDate,
    selectedDate: uiState.selectedDate,
    selectedTimeSlot: uiState.selectedTimeSlot,
    onDayClick: handleDayClick,
    onSlotClick: handleSlotClick,
    onStartDateChange: handleStartDateChange,
    onSubmit: handleSubmit,
    submitting: uiState.submitting,
    isSuccess,
    contractors: contractorsQuery.data?.contractors || [],
    selectedContractorId: contractorId,
    onContractorChange: handleContractorChange,
  }
}

export default useTreatmentSchedule
