import { getAuth } from 'firebase/auth'
import urljoin from 'url-join'

export default function createApi(base, parseResponse) {
  const _fetch = async (apiPath, options = {}) => {
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'X-Simone-Client': 'webapp',
    }

    const auth = getAuth()
    const accessToken =
      options.accessToken || (await auth.currentUser.getIdToken())

    if (accessToken) {
      options.headers.Authorization = `Bearer ${accessToken}`
    }

    const url = urljoin(base, apiPath)
    const response = await fetch(url, options)

    return parseResponse(response)
  }

  return {
    fetch: _fetch,
  }
}
